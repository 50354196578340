<template>
  <!-- eslint-disable -->
  <div class="forecast-section section-wrapper">
    <!-- forecast-heading-wrapper -->
    <div class="forecast-heading-wrapper">
      <div class="forecast-section-title">
        Compare Deals With Our Buyer's Score
      </div>
      <p class="forecast-address">
        <span class="mdi mdi-map-marker"></span> {{ getFullAddress }}
      </p>
    </div>
    <!-- /forecast-heading-wrapper -->

    <!-- forecast-buyer-score -->
    <div class="honely-property-position-relative">
      <!-- <div v-if="!isCognitoUserLoggedIn" class="container-overlay">
        <div class="overlay-wrapper">
          <p>Message prompting to login</p>
          <button class="bg-primary">Sign In</button>
        </div>
      </div> -->
      <div
        v-if="!isCognitoUserLoggedIn || !forecastAccess"
        class="container-overlay"
      >
        <div class="overlay-wrapper">
          <div
            v-if="
              !isCognitoUserLoggedIn || (!forecastAccess && !subscriptionFlag)
            "
          >
            <p>Please subscribe to view all of our statistics</p>
            <button class="bg-primary" @click="showSubscriptionPopup()">
              Subscribe
            </button>
          </div>
          <div v-else-if="subscriptionFlag && !forecastAccess">
            <p>Please purchase $2.99 to view this statistics</p>
            <button @click="showSingleSubscriptionPopup()" class="bg-primary">
              Purchase for $2.99
            </button>
          </div>
        </div>
      </div>
      <div
        class="forecast-buyer-score-main-block"
        :class="!isCognitoUserLoggedIn || !forecastAccess ? 'blocked' : ''"
      >
        <div class="forecast-buyer-score">
          <buyer-score-block :forecast="forecast" />
        </div>
        <!-- /forecast-buyer-score -->

        <!-- forecast-buyer-score-listings -->
        <div
          v-if="properties && properties.length > 0"
          class="forecast-buyer-score-listings"
        >
          <div class="buyer-score-property" v-for="(property, i) in properties">
            <div class="buyer-score-property-action-row">
              <div class="buyer-score-property-address">
                <span class="mdi mdi-map-marker"></span>
                {{ getPropertyAddress(property) }}
              </div>
              <div
                class="buyer-score-property-action"
                @click="removeProperty(i)"
              >
                <span class="mdi mdi-close"></span>
              </div>
            </div>
            <buyer-score-block :forecast="property" :key="i" />
          </div>
        </div>
        <!-- /forecast-buyer-score-listings -->

        <!-- forecast-buyer-score-search -->
        <div class="forecast-buyer-score-search">
          <section-loader :loading="loading" :noBackground="false" />
          <div class="forecast-buyer-score-search-wrapper">
            <div class="buyer-score-search-heading">Compare Property</div>
            <honely-search-simple
              @appendProperties="appendProperties"
              @loading="setLoading"
            />
          </div>
        </div>
        <!-- /forecast-buyer-score-search -->
      </div>
    </div>
    <subscription-popup
      :show="showSubscription"
      :forecastAccess="forecastAccess"
      :zipCode="getZipcode"
      :propertyId="getPropertyId"
      @toggleShow="toggleSubscriptionShow"
    />
    <single-subscription-popup
      :show="showSingleSubscription"
      :forecastAccess="forecastAccess"
      :zipCode="getZipcode"
      :propertyId="getPropertyId"
      :defaultPaymethod="defaultPaymethod"
      @toggleShow="toggleSingleSubscriptionShow"
    />
    <login-popup :show="showLogin" @toggleShow="toggleLoginPopupShow" />
  </div>
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
export default {
  name: "BuyerScore",
  components: {
    SectionLoader: () => import("@/components/SectionLoader"),
    BuyerScoreBlock: () => import("@/components/forecast/BuyerScoreBlock"),
    HonelySearchSimple: () => import("@/components/HonelySearchSimple"),
    SubscriptionPopup: () => import("@/components/forecast/SubscriptionPopup"),
    SingleSubscriptionPopup: () =>
      import("@/components/forecast/SingleSubscriptionPopup"),
    LoginPopup: () => import("@/components/login_popup/Index"),
  },
  props: {
    forecast: Object,
    subscriptionFlag: Boolean,
    defaultPaymethod: Object,
    address: String,
  },
  data() {
    return {
      // blocked: true,
      loading: false,
      properties: [],
      showSubscription: false,
      showSingleSubscription: false, 
      showLogin: false,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "loggedIn",
      "username",
      "vxAuth",
      "vxAuthDependent",
      "isCognitoUserLoggedIn",
      "cognitoUser",
    ]),
    isProperty() {
      if (this.forecast && this.forecast.property_forecast) {
        return true;
      } else {
        return false;
      }
    },
    getZipcode() {
      if (this.forecast) {
        if (this.forecast.zipcode) {
          return this.forecast.zipcode;
        } else {
          return "--";
        }
      } else {
        return "--";
      }
    },
    getPropertyId() {
      return this.forecast?.property_forecast?.address?.property_id;
    },
    getCity() {
      if (this.forecast) {
        if (this.forecast.city) {
          return this.forecast.city;
        } else {
          return "--";
        }
      } else {
        return "--";
      }
    },
    getFullAddress() {
      if (this.isProperty) {
        return this.getAddress1.trim() + " " + this.getAddress2;
      } else {
        if (this.forecast) {
          return this.forecast.zipcode;
        } else {
          return "--";
        }
      }
    },
    getAddress1() {
      if (this.forecast && this.forecast.property_forecast) {
        if (this.forecast.property_forecast.address) {
          if (
            this.forecast.city &&
            this.forecast.state &&
            this.forecast.zipcode
          ) {
            const toRemove =
              this.capitalize(this.forecast.city) +
              " " +
              this.forecast.state +
              " " +
              this.forecast.zipcode;
            return (
              this.forecast.property_forecast.address
                .replace(toRemove, "")
                .trim() + ", "
            );
          } else {
            return this.forecast.property_forecast.address;
          }
        } else {
          return "--";
        }
      } else {
        return "--";
      }
    },
    getAddress2() {
      let address2 = "";
      if (this.forecast && this.forecast.property_forecast) {
        if (this.forecast.city) {
          address2 += this.capitalize(this.forecast.city) + " ";
        }
        if (this.forecast.state) {
          address2 += this.forecast.state + " ";
        }
        if (this.forecast.zipcode) {
          address2 += this.forecast.zipcode;
        }
      }
      return address2;
    },
    forecastAccess() {
      return this.forecast.access;
    },
  },
  mounted() {
    this.properties = [];
  },
  methods: {
    goToSubscriptionPage() {
      window.location.href = "/smart-data-subscription";
    },
    capitalize(string) {
      if (string) {
        let result = "";
        const strings = string.split(" ");
        for (let i = 0; i < strings.length; i++) {
          result +=
            strings[i].charAt(0).toUpperCase() +
            strings[i].slice(1).toLowerCase();
          if (i !== strings.length - 1) {
            result += " ";
          }
        }
        return result;
      } else {
        return string;
      }
    },
    getPropertyAddress(forecast) {
      let address = "--";
      if (forecast && forecast.property_forecast) {
        if (forecast.property_forecast.address) {
          address = forecast.property_forecast.address;
        }
      }
      return address;
    },
    setLoading(loading) {
      this.loading = loading;
    },
    appendProperties(forecast) {
      this.properties.push(forecast);
    },
    removeProperty(index) {
      if (index >= 0) {
        if (index < this.properties.length) {
          this.properties.splice(index, 1);
        }
      }
    },
    showSubscriptionPopup() {
      // this.showSubscription = true
      if (this.isCognitoUserLoggedIn) {
        if (this.address) {
          this.$router.push({
            path: "/subscribe",
            query: { address: this.address },
          });
        } else {
          // this.$router.push({ path: 'Pricing', query: { address: this.getAddr1() + this.getAddr2() } })
          console.log(this.forecast)
          this.$router.push({
            name: "Subscribe",
            query: { address: this.forecast.property_forecast.address, id: this.getPropertyId },
          });
        }
      } else {
        this.showLogin = true;
      }
    },
    toggleLoginPopupShow(value) {
      this.showLogin = value;
    },
    showSingleSubscriptionPopup() {
      if (this.isCognitoUserLoggedIn) {
        if (this.address) {
          this.$router.push({
            path: "/subscribe",
            query: { address: this.address },
          });
        } else {
          // this.$router.push({ path: 'Pricing', query: { address: this.getAddr1() + this.getAddr2() } })
          console.log(this.forecast)
          this.$router.push({
            name: "Subscribe",
            query: { address: this.forecast.property_forecast.address, id: this.getPropertyId },
          });
        }
      } else {
        this.showLogin = true;
      }
    },
    toggleSubscriptionShow(value) {
      this.showSubscription = value;
    },
    toggleSingleSubscriptionShow(value) {
      this.showSingleSubscription = value;
    },
  },
};
</script>
